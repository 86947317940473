import { MODULE_NAME } from '../constants';
import * as PATHS from './paths';
import { lazy } from 'utils/codeSplitting';
import { BookIcon } from 'components/Icons/Icons';

const SourcesTablePage = lazy(() => import('../pages/SourcesTablePage'));
const SourcesDetailsPage = lazy(() => import('../pages/SourcesDetailsPage'));
const AuthorsTablePage = lazy(() => import('../pages/AuthorsTablePage'));
const AuthorDetailsPage = lazy(() => import('../pages/AuthorDetailsPage'));
const PatternsTablePage = lazy(() => import('../pages/PatternsTablePage'));
const PatternDetailsPage = lazy(() => import('../pages/PatternDetailsPage'));
const PreviewsTablePage = lazy(() =>
    import('../pages/PreviewsTablePage').then((module) => ({
        default: module.PreviewsTablePage,
    })),
);
const PreviewTypesTablePage = lazy(() =>
    import('../containers/previewTypesTableView/PreviewTypesTableView'),
);
const PreviewTypeDetailsPage = lazy(() =>
    import('../pages/PreviewTypeDetailsPage'),
);
const CategoriesTablePage = lazy(() => import('../pages/CategoriesTablePage'));
const CategoryDetailsPage = lazy(() => import('../pages/CategoryDetailsPage'));

export const stockRouting = {
    name: MODULE_NAME,
    path: `/${MODULE_NAME}`,
    routes: [
        {
            exact: true,
            name: 'authors',
            path: PATHS.AUTHORS,
            component: AuthorsTablePage,
        },
        {
            name: null,
            exact: true,
            path: PATHS.AUTHORS_DETAILS,
            component: AuthorDetailsPage,
        },
        {
            exact: true,
            name: 'sources',
            path: PATHS.SOURCES,
            component: SourcesTablePage,
        },
        {
            name: null,
            exact: true,
            path: PATHS.SOURCES_DETAILS,
            component: SourcesDetailsPage,
        },
        {
            exact: true,
            name: 'patterns',
            path: PATHS.PATTERNS,
            component: PatternsTablePage,
        },
        {
            name: null,
            exact: true,
            path: PATHS.PATTERNS_DETAILS,
            component: PatternDetailsPage,
        },
        {
            name: 'previews',
            exact: true,
            path: PATHS.PREVIEWS,
            component: PreviewsTablePage,
        },
        {
            exact: true,
            name: 'previewTypes',
            path: PATHS.PREVIEW_TYPES,
            component: PreviewTypesTablePage,
        },
        {
            name: null,
            exact: true,
            path: PATHS.PREVIEW_TYPES_DETAILS,
            component: PreviewTypeDetailsPage,
        },
        {
            exact: true,
            name: 'categories',
            path: PATHS.CATEGORIES,
            component: CategoriesTablePage,
        },
        {
            name: null,
            exact: true,
            path: PATHS.CATEGORIES_DETAILS,
            component: CategoryDetailsPage,
        },
    ],
    icon: BookIcon,
};
