import { MODULE_NAME } from './constants';
import * as PATHS from './routing/paths';

export const MODULE_ACCESSES = {
    MODULE: MODULE_NAME,
    PATHS,
    RESOURCES: {
        SOURCES: {
            CREATE: `${MODULE_NAME}_resources_sources_create`,
            FETCH: `${MODULE_NAME}_resources_sources_fetch`,
            EDIT: `${MODULE_NAME}_resources_sources_edit`,
            DELETE: `${MODULE_NAME}_resources_sources_delete`,
        },
        AUTHORS: {
            CREATE: `${MODULE_NAME}_resources_authors_create`,
            FETCH: `${MODULE_NAME}_resources_authors_fetch`,
            EDIT: `${MODULE_NAME}_resources_authors_edit`,
            DELETE: `${MODULE_NAME}_resources_authors_delete`,
        },
        PATTERNS: {
            CREATE: `${MODULE_NAME}_resources_patterns_create`,
            FETCH: `${MODULE_NAME}_resources_patterns_fetch`,
            EDIT: `${MODULE_NAME}_resources_patterns_edit`,
            DELETE: `${MODULE_NAME}_resources_patterns_delete`,
        },
        PREVIEW_TYPES: {
            CREATE: `${MODULE_NAME}_resources_preview_types_create`,
            FETCH: `${MODULE_NAME}_resources_preview_types_fetch`,
            EDIT: `${MODULE_NAME}_resources_preview_types_edit`,
            DELETE: `${MODULE_NAME}_resources_preview_types_delete`,
        },
        CATEGORIES: {
            CREATE: `${MODULE_NAME}_resources_categories_create`,
            FETCH: `${MODULE_NAME}_resources_categories_fetch`,
            EDIT: `${MODULE_NAME}_resources_categories_edit`,
        },
        PRODUCT_SET_PREVIEW_TYPES: {
            CREATE: `${MODULE_NAME}_resources_product_set_preview_types_create`,
            FETCH: `${MODULE_NAME}_resources_product_set_preview_types_fetch`,
            EDIT: `${MODULE_NAME}_resources_product_set_preview_types_edit`,
            DELETE: `${MODULE_NAME}_resources_product_set_preview_types_delete`,
        },
        PRODUCT_SET_PREVIEWS: {
            CREATE: `${MODULE_NAME}_resources_product_set_preview_create`,
            FETCH: `${MODULE_NAME}_resources_product_set_preview_fetch`,
            DELETE: `${MODULE_NAME}_resources_product_set_preview_delete`,
        },
    },
};
