import get from 'lodash/get';

const STOCK_URL = get(window, 'env.STOCK_API_URL', '');
const STOCK_MEDIA_FILES_URL = get(window, 'env.STOCK_MEDIA_FILES_URL', '');
const MEDIA_FILES_URL = get(window, 'env.MEDIA_FILES_URL', '');

const AUTHORS_URLS = {
    DELETE_AUTHOR: `${STOCK_URL}/api/v2/authors/{id}`,
    GET_AUTHOR: `${STOCK_URL}/api/v2/authors/{id}`,
    GET_AUTHORS: `${STOCK_URL}/api/v2/authors`,
    PATCH_AUTHOR: `${STOCK_URL}/api/v2/authors/{id}`,
    POST_AUTHOR: `${STOCK_URL}/api/v2/authors`,
} as const;

const CATEGORIES_URLS = {
    GET_CATEGORIES: `${STOCK_URL}/api/v2/categories`,
    GET_CATEGORY: `${STOCK_URL}/api/v2/categories/{id}`,
    PATCH_CATEGORY: `${STOCK_URL}/api/v2/categories/{id}`,
    POST_CATEGORY: `${STOCK_URL}/api/v2/categories`,
} as const;

const COLORS_URLS = {
    GET_COLOR: `${STOCK_URL}/api/v1/colors/{id}`,
    GET_COLORS: `${STOCK_URL}/api/v1/colors`,
} as const;

const EXTERNAL_PATTERNS_URLS = {
    PATCH_EXTERNAL_PATTERN: `${STOCK_URL}/api/v2/external_patterns/{id}`,
    POST_EXTERNAL_PATTERN: `${STOCK_URL}/api/v2/external_patterns`,
} as const;

const PATTERNS_URLS = {
    DELETE_PATTERN: `${STOCK_URL}/api/v1/patterns/{id}`,
    EDIT_PATTERNS_PRINT_SPEC: `${STOCK_URL}/api/v1/print_specs/{id}/patterns`,
    GET_PATTERN_TAGS: `${STOCK_URL}/api/v1/patterns/tags`,

    GET_PATTERN_BY_CODE: `${STOCK_URL}/api/v2/patterns/by_code/{id}`,

    GET_PATTERN: `${STOCK_URL}/api/v5/patterns/{id}`,
    GET_PATTERNS: `${STOCK_URL}/api/v5/patterns`,
    POST_ADD_PREVIEW_TO_PATTERN: `${STOCK_URL}/api/v1/patterns/{id}/previews`,
    POST_PATTERN: `${STOCK_URL}/api/v5/patterns`,
    PATCH_PATTERN: `${STOCK_URL}/api/v5/patterns/{id}`,
} as const;

const PREVIEW_TYPES_URLS = {
    POST_PREVIEW_TYPE: `${STOCK_URL}/api/v2/preview_types`,
    DELETE_PREVIEW_TYPES: `${STOCK_URL}/api/v2/preview_types/{id}`,
    GET_PREVIEW_TYPES: `${STOCK_URL}/api/v2/preview_types`,
    GET_PREVIEW_TYPE_BY_ID: `${STOCK_URL}/api/v2/preview_types/{id}`,
    PATCH_PREVIEW_TYPE_BY_ID: `${STOCK_URL}/api/v2/preview_types/{id}`,
} as const;

const PREVIEWS_URLS = {
    DELETE_PREVIEW: `${STOCK_URL}/api/v1/patterns/{patternId}/previews/{previewId}`,
    DELETE_PREVIEWS: `${STOCK_URL}/api/v1/previews/bulk_delete`,
    GET_PREVIEWS: `${STOCK_URL}/api/v1/pattern-previews`,
    DOWNLOAD_PREVIEWS: `${STOCK_URL}/api/v1/previews/bulk_download`,
} as const;

const PRODUCT_SET_PREVIEW_TYPES_URLS = {
    CREATE_PRODUCT_SET_PREVIEW_TYPE: `${STOCK_URL}/api/v1/product_set_preview_types`,
    DELETE_PRODUCT_SET_PREVIEW_TYPE: `${STOCK_URL}/api/v1/product_set_preview_types/{id}`,
    EDIT_PRODUCT_SET_PREVIEW_TYPE: `${STOCK_URL}/api/v1/product_set_preview_types/{id}`,
    GET_PRODUCT_SET_PREVIEW_TYPE: `${STOCK_URL}/api/v1/product_set_preview_types/{id}`,
    GET_PRODUCT_SET_PREVIEW_TYPES_LIST: `${STOCK_URL}/api/v1/product_set_preview_types`,
} as const;

const PRODUCT_SET_PREVIEWS_URLS = {
    CREATE_PRODUCT_SET_PREVIEW: `${STOCK_URL}/api/v1/product_set_previews`,
    DELETE_PRODUCT_SET_PREVIEW: `${STOCK_URL}/api/v1/product_set_previews/{id}`,
    GET_PRODUCT_SET_PREVIEWS_LIST: `${STOCK_URL}/api/v1/product_set_previews`,
} as const;

const SOURCE_CATEGORIES_URLS = {
    GET_SOURCE_CATEGORIES: `${STOCK_URL}/api/v2/source_categories`,
    GET_SOURCE_CATEGORIES_BY_ID: `${STOCK_URL}/api/v2/source_categories/{id}`,
} as const;

const SOURCES_URLS = {
    DELETE_SOURCE: `${STOCK_URL}/api/v2/sources/{id}`,
    GET_SOURCE: `${STOCK_URL}/api/v2/sources/{id}`,
    GET_SOURCES: `${STOCK_URL}/api/v2/sources`,
    PATCH_SOURCE: `${STOCK_URL}/api/v2/sources/{id}`,
    POST_SOURCE: `${STOCK_URL}/api/v2/sources`,
} as const;

const STOCK_MEDIA_FILES_URLS = {
    DOWNLOAD_PREVIEW: `${STOCK_MEDIA_FILES_URL}/api/v1/files/{id}/download`,
    DOWNLOAD_SOURCE: `${STOCK_MEDIA_FILES_URL}/api/v1/files/{id}/download`,
    DOWNLOAD_FILE_RESOURCE: `${STOCK_MEDIA_FILES_URL}/api/v1/{id}/download`,
    DOWNLOAD_PRINT_SPEC_FILE: `${STOCK_MEDIA_FILES_URL}/api/v1/print-spec/files/{id}/public-link`,
    DOWNLOAD_FILE: `${STOCK_MEDIA_FILES_URL}/api/v1/files/{id}/download`,
    DOWNLOAD_PATTERN_FILE: `${STOCK_MEDIA_FILES_URL}/api/v1/files/{id}/public-link`,
} as const;

const MEDIA_FILES_URLS = {
    DOWNLOAD_PRODUCT_SET_PREVIEW: `${MEDIA_FILES_URL}/api/v1/{id}/download`,
    DOWNLOAD_PRODUCT_SET_PATTERN_FILE: `${MEDIA_FILES_URL}/api/v1/files/{id}/public-link`,
} as const;

const COLOR_DEFINITIONS = {
    GET_COLOR_DEFINITION: `${STOCK_URL}/api/v1/color_definitions`,
} as const;

const HEX_COLOR = {
    POST_HEX_COLOR_GENERATE: `${STOCK_URL}/api/hex_color/generate`,
} as const;

export const URLS = {
    ...AUTHORS_URLS,
    ...CATEGORIES_URLS,
    ...COLORS_URLS,
    ...EXTERNAL_PATTERNS_URLS,
    ...PATTERNS_URLS,
    ...PREVIEW_TYPES_URLS,
    ...PREVIEWS_URLS,
    ...PRODUCT_SET_PREVIEW_TYPES_URLS,
    ...PRODUCT_SET_PREVIEWS_URLS,
    ...SOURCE_CATEGORIES_URLS,
    ...SOURCES_URLS,
    ...STOCK_MEDIA_FILES_URLS,
    ...MEDIA_FILES_URLS,
    ...COLOR_DEFINITIONS,
    ...HEX_COLOR,
} as const;

export const DEFAULTS = {
    AUTHORS_AUTOCOMPLETE_PER_PAGE: 50,
    AUTHORS_LIST_PER_PAGE: 20,
    CATEGORIES_AUTOCOMPLETE_PER_PAGE: 1000,
    CATEGORIES_LIST_PER_PAGE: 10,
    PREVIEWS_LIST_PER_PAGE: 20,
    SOURCES_AUTOCOMPLETE_PER_PAGE: 50,
    SOURCES_LIST_PER_PAGE: 20,
    BATCH_DOWNLOAD_INTERVAL: 2000,
} as const;

export const APP_ALERTS = {
    CREATE_AUTHOR_SUCCESS: 'stock:alerts.authorCreated',
    CREATE_CATEGORY_SUCCESS: 'stock:alerts.categoryCreated',
    CREATE_SOURCE_SUCCESS: 'stock:alerts.sourceCreated',
    DELETE_AUTHOR_SUCCESS: 'stock:alerts.authorDeleted',
    DELETE_PREVIEW_SUCCESS: 'stock:alerts.previewDeleted',
    DELETE_SOURCE_SUCCESS: 'stock:alerts.sourceDeleted',
    EDIT_AUTHOR_SUCCESS: 'stock:alerts.authorChanged',
    EDIT_CATEGORY_SUCCESS: 'stock:alerts.categoryChanged',
    EDIT_SOURCE_SUCCESS: 'stock:alerts.sourceChanged',
} as const;

export const PREVIEWS_UPLOAD_CONFIG = {
    ACCEPTED_MIME_TYPES: [
        'image/jpeg',
        'image/gif',
        'image/svg+xml',
        'image/tiff',
        'image/bmp',
        'image/png',
    ],
    MAX_FILE_SIZE: 30 * 1024 * 1024,
    MAX_FILES_LIMIT: 150,
    PREVIEWS_FILE_LIMIT: 150,
    UPPY: {
        id: 'stock-preview-files',
        autoProceed: false,
        debug: true,
    },
    TUS: {
        endpoint:
            STOCK_MEDIA_FILES_URL +
            '/api/v2/previews/{patternCode}/preview-type/{preview-type}',
        chunkSize: 10000000, // 10mb
        retryDelays: [0, 1000, 3000, 5000],
    },
} as const;
