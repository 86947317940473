import type { AjaxService } from '../../../ajax/AjaxService';
import type { URLS } from '../../constants';
import type { default as errorHandlersFactory } from '../../errorHandlers';
import { ApplicationJsonHeaders } from '../../../constants';
import type {
    GetProductionOrderThumbnailResponse,
    ProductionOrderDocument,
    ProductionOrdersV3CollectionOutputDto,
    ProductionOrdersV3ItemOutputDto,
    ProductionOrdersV3Parameters,
} from './productionOrders.type';
import qs from 'qs';
import {
    AjaxResponse,
    AsyncRequestPaginator,
    ListResponse,
} from '../../../utils/AsyncRequestPaginator';
import { DeepPartial } from 'utils/types/deepPartial';

export class ProductionOrdersActions {
    private static instance: ProductionOrdersActions | undefined;

    private readonly asyncRequestPaginator: AsyncRequestPaginator<
        ProductionOrderDocument,
        ProductionOrderDocument
    >;

    private constructor(
        private readonly ajaxService: AjaxService,
        private readonly urls: typeof URLS,
        private readonly errorHandlers: ReturnType<typeof errorHandlersFactory>,
    ) {
        this.getDocuments = this.getDocuments.bind(this);
        this.getAllDocuments = this.getAllDocuments.bind(this);
        this.getProductionOrder = this.getProductionOrder.bind(this);
        this.getProductionOrderCollection =
            this.getProductionOrderCollection.bind(this);
        this.getProductionOrderThumbnail =
            this.getProductionOrderThumbnail.bind(this);

        this.asyncRequestPaginator = new AsyncRequestPaginator<
            ProductionOrderDocument,
            ProductionOrderDocument
        >(this.getDocuments, (data) => data);
    }

    static getInstance(
        ajaxService: AjaxService,
        urls: typeof URLS,
        errorHandlers: ReturnType<typeof errorHandlersFactory>,
    ): ProductionOrdersActions {
        if (!ProductionOrdersActions.instance) {
            this.instance = new ProductionOrdersActions(
                ajaxService,
                urls,
                errorHandlers,
            );
        }

        return this.instance;
    }

    getDocuments(params: {
        page: number;
        id: string;
    }): Promise<AjaxResponse<ListResponse<ProductionOrderDocument>>> {
        const url = this.urls.GET_PRODUCTION_ORDER_DOCUMENTS.replace(
            '{id}',
            params.id,
        );

        return this.ajaxService.get({
            url,
            config: {
                headers: ApplicationJsonHeaders,
                params: { page: params.page },
                paramsSerializer: (queryParams) => qs.stringify(queryParams),
            },
            errorConfig: { onError: this.errorHandlers.get },
            onError: this.errorHandlers.get,
        });
    }

    async getAllDocuments(id: string): Promise<ProductionOrderDocument[]> {
        const data = await this.asyncRequestPaginator.fetchFullList({ id });

        return data.items;
    }

    async getProductionOrder(
        id: string,
    ): Promise<ProductionOrdersV3ItemOutputDto> {
        const url = this.urls.GET_V3_PRODUCTION_ORDER.replace('{id}', id);

        const response = await this.ajaxService.get({
            url,
            config: { headers: ApplicationJsonHeaders },
            errorConfig: { onError: this.errorHandlers.get },
            onError: this.errorHandlers.get,
        });

        return response.data;
    }

    async getProductionOrderCollection(
        parameters: DeepPartial<ProductionOrdersV3Parameters> = {},
    ): Promise<ProductionOrdersV3CollectionOutputDto> {
        const url = this.urls.GET_V3_PRODUCTION_ORDERS;

        const response: { data: ProductionOrdersV3CollectionOutputDto } =
            await this.ajaxService.get({
                url,
                config: {
                    headers: ApplicationJsonHeaders,
                    params: parameters,
                    paramsSerializer: qs.stringify,
                },
                errorConfig: { onError: this.errorHandlers.get },
                onError: this.errorHandlers.get,
            });

        return response.data;
    }
    async getProductionOrderThumbnail(
        id: string,
        params?: { size: string },
    ): Promise<GetProductionOrderThumbnailResponse> {
        const url = this.urls.GET_V3_PRODUCTION_ORDER_THUMBNAIL.replace(
            '{id}',
            id,
        );

        const { data } = await this.ajaxService.get({
            url,
            config: {
                headers: ApplicationJsonHeaders,
                params: { size: params?.size },
                paramsSerializer: (queryParams) => qs.stringify(queryParams),
            },
            errorConfig: {
                logError: false,
                addGenericAlert: false,
                addGenericFailureAlert: false,
            },
        });

        return data;
    }
}
