import get from 'lodash/get';
import { mapIntermediateProductFromApi } from '../mappers/intermediateProduct';
import {
    mapPrintSpecsIntermediateProductToApi,
    mapPrintSpecsIntermediateProductsFromApi,
    mapPrintSpecFromApi,
    mapCreatePrintSpecToApi,
    mapEditPrintSpecToApi,
    mapPrintSpecFilesFromApi,
    mapPrintSpecFilesCreateToApi,
} from '../mappers/printSpec';
import {
    getSortParams,
    getFilterParams,
    configError,
} from 'services/utils/utils';
import { ApplicationJsonHeaders } from '../../constants';

import type { ArmApiService } from '../ArmApiService';
import type {
    IArmApiServiceServices,
    IArmApiServiceConst,
} from '../armApi.type';

class PrintSpecs {
    private readonly URLS: IArmApiServiceConst['URLS'];
    private readonly DEFAULTS: IArmApiServiceConst['DEFAULTS'];
    private readonly PARAMS: IArmApiServiceConst['PARAMS'];

    constructor(
        private readonly armApiService: ArmApiService,
        private readonly services: IArmApiServiceServices,
        {
            URLS,
            DEFAULTS,
            PARAMS,
        }: Pick<IArmApiServiceConst, 'URLS' | 'DEFAULTS' | 'PARAMS'>,
    ) {
        this.URLS = URLS;
        this.DEFAULTS = DEFAULTS;
        this.PARAMS = PARAMS;

        this.createPrintSpec = this.createPrintSpec.bind(this);
        this.editPrintSpec = this.editPrintSpec.bind(this);
        this.getPrintSpecsList = this.getPrintSpecsList.bind(this);
        this.getPrintSpecById = this.getPrintSpecById.bind(this);
        this.getIntermediateProducts = this.getIntermediateProducts.bind(this);
        this.getPrintSpecTechnologyQuantity =
            this.getPrintSpecTechnologyQuantity.bind(this);
        this.createPrintSpecFile = this.createPrintSpecFile.bind(this);
        this.deletePrintSpec = this.deletePrintSpec.bind(this);
    }

    createPrintSpec = async (newPrintSpec) => {
        const { ajax } = this.services;
        const { errorHandlers } = this.armApiService;

        const url = this.URLS.POST_ARM_PRINT_SPEC_V3;
        const errorConfig = configError('post', errorHandlers);

        return await ajax.post({
            url,
            data: mapCreatePrintSpecToApi(newPrintSpec),
            errorConfig,
        });
    };
    async getPrintSpecById(
        id: string,
        config: { disableError?: boolean } | undefined,
    ) {
        const { ajax } = this.services;
        const { errorHandlers } = this.armApiService;

        const url = this.URLS.GET_ARM_PRINT_SPEC_BY_ID_V3.replace('{id}', id);
        const errorConfig = configError('get', errorHandlers);

        const response = await ajax.get({
            url,
            errorConfig: config?.disableError
                ? { addGenericAlert: false }
                : errorConfig,
        });

        return mapPrintSpecFromApi(get(response, 'data', {}));
    }
    async getPrintSpecsList(params = {}) {
        const { ajax } = this.services;
        const { errorHandlers, paramsSerializer } = this.armApiService;

        const url = this.URLS.GET_ARM_PRINT_SPECS_V3;
        const errorConfig = configError('get', errorHandlers);
        const {
            page = 1,
            per_page = this.DEFAULTS.PRINT_SPECS_PER_PAGE,
            sort: sortParams,
            filters = {},
        } = params;
        const config = {
            params: {
                page,
                per_page,
            },
            paramsSerializer,
        };

        const sort = getSortParams(
            this.PARAMS.PRINT_SPEC_PARAMS.SORTING_KEYS,
            sortParams,
        );
        const filter =
            getFilterParams(this.PARAMS.PRINT_SPEC_PARAMS.FILTERS_KEYS, {
                ...filters,
                externalProducer: filters.external_producer,
            }) || {};

        if (sort) config.params.order = sort;
        if (filter['status']) config.params['status'] = filter['status'];
        if (filters.search) {
            config.params.name = filters.search;
        }
        if (typeof filters?.external_producer === 'boolean') {
            config.params.externalProducer = filters.external_producer;
        }

        const response = await ajax.get({ url, config, errorConfig });

        return {
            items: get(response, 'data.hydra:member', []).map(
                mapPrintSpecFromApi,
            ),
            totalItems: get(response, 'data.hydra:totalItems', 0),
        };
    }
    async getPrintSpecFilesList({ id } = {}) {
        const { ajax } = this.services;
        const { errorHandlers, paramsSerializer } = this.armApiService;

        const url = this.URLS.GET_ARM_PRINT_SPEC_FILES_V3.replace('{id}', id);
        const errorConfig = configError('get', errorHandlers);
        const config = { paramsSerializer };

        const response = await ajax.get({ url, config, errorConfig });

        return {
            items: get(response, 'data.hydra:member', []).map(
                mapPrintSpecFilesFromApi,
            ),
            totalItems: get(response, 'data.hydra:totalItems', 0),
        };
    }
    async getIntermediateProducts({ printSpecId }) {
        const { ajax } = this.services;

        const url = this.URLS.GET_ARM_PRINT_SPEC_BY_ID_V3.replace(
            '{id}',
            printSpecId,
        );

        const response = await ajax.get({ url });

        return mapPrintSpecsIntermediateProductsFromApi(response.data);
    }
    async editPrintSpec(printSpecId, printSpecData, intermediateProducts = []) {
        const { ajax } = this.services;
        const { errorHandlers } = this.armApiService;

        const url = this.URLS.PUT_PRINT_SPECS_V3.replace('{id}', printSpecId);
        const errorConfig = configError('put', errorHandlers);

        const response = await ajax.put({
            url,
            data: {
                ...mapEditPrintSpecToApi(printSpecData),
                intermediate_products: intermediateProducts.map(
                    mapPrintSpecsIntermediateProductToApi,
                ),
            },
            errorConfig,
        });

        return {
            items: get(response, 'intermediate_products', []).map(
                (interProduct) => mapIntermediateProductFromApi(interProduct),
            ),
            itemCount: get(response, 'intermediate_products.length', 0),
        };
    }
    getPrintSpecTechnologyQuantity = async (id) => {
        const { ajax } = this.services;

        const url = this.URLS.GET_PRINT_SPECS_TECHNOLOGY_QUANTITY_V3.replace(
            '{id}',
            id,
        );
        const config = {
            headers: ApplicationJsonHeaders,
        };
        const { data } = await ajax.get({ url, config });

        return data;
    };

    async createPrintSpecFile({ data, id }) {
        const { ajax } = this.services;
        const { errorHandlers } = this.armApiService;

        const url = this.URLS.POST_ARM_PRINT_SPEC_FILES_V3.replace('{id}', id);
        const config = {
            headers: ApplicationJsonHeaders,
        };

        const errorConfig = {
            addGenericAlert: false,
            throwError: errorHandlers.post,
        };

        return await ajax.post({
            url,
            data: mapPrintSpecFilesCreateToApi(data),
            config,
            errorConfig,
        });
    }

    async deletePrintSpec(id: string) {
        const { ajax } = this.services;
        const { errorHandlers } = this.armApiService;
        const url = this.URLS.DELETE_ARM_PRINT_SPEC_V3.replace('{id}', id);
        const config = {
            headers: ApplicationJsonHeaders,
        };

        const errorConfig = {
            addGenericAlert: false,
            throwError: errorHandlers.delete,
        };

        return await ajax.delete({
            url,
            config,
            errorConfig,
        });
    }
}

export default PrintSpecs;
