const STOCK_MEDIA_FILES_URL = window.env && window.env.STOCK_MEDIA_FILES_URL;

export const PATTERNS_UPLOAD_CONFIG = {
    UPPY: {
        id: 'stock-patterns',
    },
    TUS: {
        endpoint: STOCK_MEDIA_FILES_URL + '/api/v1/pattern-files/',
    },
};

export const PREVIEW_TYPES_UPLOAD_CONFIG = {
    UPPY: {
        id: 'stock-preview-types',
    },
    TUS: {
        endpoint: STOCK_MEDIA_FILES_URL + '/api/v1/preview-type-files/',
    },
};

export const PAINT_COLOR_PALETTES_UPLOAD_CONFIG = {
    UPPY: {
        id: 'stock-paint-color-palettes-files',
    },
    TUS: {
        endpoint: STOCK_MEDIA_FILES_URL + '/api/v1/palette-files/',
    },
};

export const DRAFTS_UPLOAD_CONFIG = {
    UPPY: {
        id: 'stock-draft-files',
    },
    TUS: {
        endpoint: STOCK_MEDIA_FILES_URL + '/api/v1/draft-files',
    },
};
